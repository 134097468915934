import "$styles/index.scss"
import "$styles/syntax-highlighting.css"
import Idiomorph from "idiomorph/dist/idiomorph.cjs.js" 
window.Idiomorph = Idiomorph
import * as Turbo from "@hotwired/turbo"
import './home'
function render(prevEl, newEl){ 
    Idiomorph.morph(prevEl, newEl); 
}
Turbo.PageRenderer.renderElement = render
 

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

const getRect = node => node.getBoundingClientRect() 

function getLang() {
  if (navigator.languages != undefined) 
    return navigator.languages[0].split('-')[0]; 
  return navigator.language.split('-')[0];
}
function redirect_language(){
    if(localStorage.getItem("language_redirect"))return;
    let lang = getLang()
    let available = document.getElementById("languages")
    window.a = available
    for(let i = 0; i < available.children.length; i++){
        node = available.children[i]
        if(node.innerText == lang && !node.classList.contains("selected")){
            node.click()
        }
    }
    localStorage.setItem("language_redirect", true)
}

window.addEventListener("load", ()=>{
    redirect_language()
})
