import anime from 'animejs/lib/anime.es.js';

let remArt = [
"⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀",
"⠀⠀⠀⠀⠀⠀⠀⢀⣶⣿⣿⣿⡿⣷⣄⠀⠀⠀⠀⠀⠀⠀",
"⠀⠀⠀⠀⠀⠀⠀⣼⣿⣿⣿⣿⣿⣿⣿⡆⠀⠀⠀⠀⠀⠀",
"⠀⠀⠀⠀⠀⠀⠀⣾⢳⣽⣿⢱⣮⣿⣿⡇⠀⠀⠀⠀⠀⠀",
"⠀⠀⠀⠀⠀⠀⠀⣿⠟⠉⠈⢉⡳⣿⣿⣇⠀⠀⠀⠀⠀⠀",
"⠀⠀⠀⠀⠀⠀⠀⣽⡿⠴⠠⠕⠊⢻⣯⣿⣆⠀⠀⠀⠀⠀",
"⠀⠀⠀⠀⠀⢠⣾⡟⠀⠀⠀⠀⠀⠀⢻⣿⣿⣦⡀⠀⠀⠀",
"⠀⠀⠀⠀⢰⣿⡿⠀⠀⠀⠀⠀⠀⠀⠈⢿⣿⣿⣷⠀⠀⠀",
"⠀⠀⠀⢀⣾⣿⠁⠀⠀⠀⠀⠀⠀⠀⠀⠘⣿⣿⣿⣇⠀⠀",
"⠀⠀⠀⣼⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⣿⣿⣿⠀⠀",
"⠀⣀⣠⠟⠉⢳⣄⠀⠀⠀⠀⠀⠀⠀⢠⠖⣿⣿⣯⢯⠀⠀",
"⢸⠀⠀⠀⠀⠀⠹⣷⡄⠀⠀⠀⠀⠀⢸⠁⠉⠉⠁⠘⢧⡀",
"⣸⠀⠀⠀⠀⠀⠀⠹⣁⠀⠀⠀⣀⣴⡟⠀⠀⠀⠀⠀⢀⡽",
"⠻⠦⣤⣀⣀⠀⠀⠀⣸⠿⠿⠿⣿⣿⣇⠀⠀⢀⡴⠚⠉⠀",
"⠀⠀⠀⠈⠉⠛⠒⠚⠁⠀⠀⠀⠀⠀⠙⠓⠒⠋⠀⠀⠀⠀",
]
function enableRem(){
    rem = document.getElementById("rem")
    if(!rem) return false
    rem.innerHTML = remArt.map(line=>"<div>"+line.split("").map(l=>"<span style='transform: scale(0)'>"+l+"</span>").join("")+"</div>").join("")
    for(let i = 0; i<rem.children.length; i++) rem.children[i].setAttribute("data-grid-pos", i)
    anime({
        targets: rem.querySelectorAll("span"),
        delay: anime.stagger(40, {grid, from: 'center'}),
        scale: [
            {value: 0.8, easing: 'easeInOutQuad', duration: 300},
            {value: 1, easing: 'easeInOutQuad', duration: 700}
        ],
    }).finished.then(()=>{
        anime({
                targets: rem.children,
                delay: anime.stagger(40, {grid, from: 'center' }),
                scale: [
                    {value: 0.9, easing: 'easeInOutQuad', duration: 300},
                    {value: 1, easing: 'easeInOutQuad', duration: 300}
                ],
                loop: true,
                direction: { reverse: true }
            })
    })
    
    const remClick = e => {
        console.log("click")
        console.log(e)
        window.e = e
        console.log(e.target.dataset)
        let index = parseInt(e.target.getAttribute("data-grid-pos"))
        anime({
            targets: rem.children,
            delay: anime.stagger(20, {grid, from: index }),
            scale: [
                {value: 1.35, easing: 'easeInSine', duration: 230},
                {value: 0, easing: 'easeInOutQuad', duration: 130},
                {value: 1, easing: 'easeInOutQuad', duration: 700}
            ],
        })
    }
    for(const l of rem.children)l.addEventListener("click", remClick)
}

enableRem()
